import React from "react";
import Seo from "../components/seo/Seo";
import Layout from "../components/shared/Layout";

// Cultura research is currently only shared internally and to approved researchers. We'll notify you when we publish public research.
export default function Research() {
  return <Layout>
    <Seo
      title="Cultured Meat Scientific & Market Research"
    />
  </Layout>;
}
